@import './Variables';

.App {
  text-align: left;
}

.container {
  max-width: 1024px;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
