.col {
  padding: 1rem 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.bottomRow {
  margin-top: 1rem;
}

.labels {
  font-weight: bold;
}

.approve {
  color: green;
  margin: 0rem 0.3rem;
}

.reject {
  color: red;
  margin: 0rem 0.3rem;
}

.approveUnactive {
  color: #ccc;
  margin: 0rem 0.3rem;
  &:hover {
    color: green;
    cursor: pointer; 
  }
}

.rejectUnactive {
  color: #ccc;
  margin: 0rem 0.3rem;
  &:hover {
    color: red;
    cursor: pointer;  
  }
}
