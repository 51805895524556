.logo {
    height: 40px;
    max-height: 40px;
    width: auto;
  }

.navbar {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;
  margin-bottom: 20px;
}

