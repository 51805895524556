.subnav {
  background-color: #E2E4E8;
  vertical-align: middle;
  margin-top: -20px;
  
  .link {
      height: 55px;
      align-items: center;
      
      a {
          font-size: 12pt;
          color: #3d3d3d;
          padding-left: 0;        
        }

      a:hover {
        text-decoration: underline;
      }
  }

}