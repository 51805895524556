@import '../../style/Variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: 'Raleway', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

.componentContainer {
  padding: 0rem 1rem;

  @media (min-height: 633px) and (min-width: 1024px) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.container::-webkit-scrollbar {
  width: 3px;
}

.placeholderImg {
  width: 256px;
  height: 225px;
  background-color: #d8d8d8;
  display: 'flex';
  align-items: center;
  justify-content: center;
}

.image {
  width: 256px;
  height: 225px;
  object-fit: cover;
}

.container {
  position: relative;
  margin-top: -183%;
  margin-left: 3.5%;
  width: 94%;
  overflow-y: scroll; // Add this line to enable the scrollbar
  height: 495px;

  @-moz-document url-prefix() {
    scrollbar-width: none;
    width: 86.3% !important;
  }

  .textContainer {
    padding: 16px 10px 0px 10px;
    height: 215px;
  }

  .overflowY {
    overflow-y: auto;
  }

  .brand {
    font-size: 14px;
    font-weight: 500;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .header {
    font-size: 18px;
    font-weight: 800;
    margin-top: 14;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .body {
    font-size: 12px;
    margin-top: 14;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .disclaimer {
    font-size: 10px;
    margin-top: 12px;
    font-style: italic;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    font-weight: 700;
  }

  .divider {
    border-top: 1px solid #bbb;
    margin: 0rem 0rem;
  }
}

.phoneFrame {
  position: relative;
}

.dashes {
  margin: 0;
  line-height: 0.5rem;
}

.discountCode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  background-color: #ffff00;
  padding: 7px 18px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
}

.btnContainer {
  position: absolute;
  bottom: 0%;
  width: 235px;
  text-align: center;
}

.btnCont1Extra {
  position: absolute;
  bottom: 0%;
}

.btnCont2Extra {
  position: sticky;
  bottom: 0%;
}

.btn {
  flex: 1;
  flex-direction: row;
  background-color: #2430bb;
  padding: 7px 18px;
  border-radius: 20px;
}

.btnText {
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
  color: white !important;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.address {
  text-align: left;
  float: left;
  font-size: 12px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.addressTitle {
  text-align: left;
  float: left;
  font-weight: 600;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
